const formatDateTime = (datetime) => {
    const date = new Date(datetime)
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' }; // Format tanggal
    const optionsTime = { hour: '2-digit', minute: '2-digit' }; // Format waktu

    const formattedDate = date.toLocaleDateString('id-ID', optionsDate);
    const formattedTime = date.toLocaleTimeString('id-ID', optionsTime);

    return `${formattedDate}, pukul ${formattedTime}`
}

export default formatDateTime