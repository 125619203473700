import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import telegram from "../utils/telegram"
import formatDateTime from "../utils/formatDate"
import Swal from "sweetalert2"

const useFormRequest = () => {
  const navigate = useNavigate()

  const [reporter, setReporter] = useState({ name: "", telephone: "" })
  const [event, setEvent] = useState({ name: "", description: "", place: "", schedule: "" })
  const [contactPerson, setContactPerson] = useState({ name: "", telephone: "" })

  const [selected, setSelected] = useState([])

  const resetForm = () => {
    setReporter({ name: "", telephone: "" })
    setEvent({ name: "", description: "", place: "", schedule: "" })
    setContactPerson({ name: "", telephone: "" })
    setSelected([])
  }

  const options = [
    {
        label: "Berita",
        value: "Berita"
    },
    {
        label: "Reels",
        value: "Reels"
    },
    {
        label: "PENS TV",
        value: "PENS TV"
    }
  ]

  const validateForm = () => {
    if(selected.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Produk yang ingin dihasilkan tidak boleh kosong",
      })
      return false
    }
    return true
  }

  const sendData = async (e) => {
    e.preventDefault()

    const formattedSchedule = formatDateTime(event.schedule)
    const selectedProducts = selected.map((item) => item.label).join(", ")

    const message = `Halo, ENT Pals!%0A` +
      `Ada permintaan liputan baru yang menarik! Berikut detail acaranya:%0A%0A` +
      `📌 Nama Pelapor: ${reporter.name} (${reporter.telephone})%0A%0A` +
      `🎉 Nama Acara: ${event.name}%0A` +
      `📄 Deskripsi Acara: ${event.description}%0A` +
      `📍 Lokasi: ${event.place}%0A` +
      `🗓️ Waktu Pelaksanaan: ${formattedSchedule}%0A%0A` +
      `👤 Kontak Person:%0A` +
      `Nama: ${contactPerson.name}%0A` +
      `Telepon: ${contactPerson.telephone}%0A%0A` +
      `📦 Produk yang ingin dihasilkan: ${selectedProducts} %0A%0A` +
      `Segera persiapkan tim terbaik untuk meliput acara ini. Terima kasih!`

    
    if(!validateForm()) return

    const url = `https://api.telegram.org/bot${telegram.token}/sendMessage?chat_id=${telegram.chat_id}&text=${message}`

    try {
      const response = await axios.post(url)
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Permintaan Anda Terkirim",
            confirmButtonAriaLabel: "OK",
            confirmButtonText: "OK"
          }).then((res) => {
            resetForm()
            // pindah ke halaman home
            navigate("/")
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Permintaan anda tidak dapat terkirim, silahkan coba lagi"
          })
        }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${error}`
      })
    }
  }

  return {
    reporter,
    setReporter,
    event,
    setEvent,
    contactPerson,
    setContactPerson,
    selected,
    setSelected,
    options,
    sendData,
  }
}

export default useFormRequest
