import React from 'react'
import NavbarWithCTAButton from '../Common/NavbarComp'
import useFormRequest from '../../hooks/useFormRequest'
import union from "../../asset/union.png"
import FormRequest from '../Common/FormRequest'
import Footer from '../Common/Footer'

const Request = () => {
    const { reporter, setReporter, event, setEvent, contactPerson, setContactPerson, selected, setSelected, options, sendData } = useFormRequest();
  return (
    <section className='bg-[#134679] pt-24 bg-no-repeat' style={{ backgroundImage: `url(${union})` }}>
        <NavbarWithCTAButton />
        {/* <img src="/union.png" className="w-full" alt="Hero" /> */}
        <div className="flex flex-col gap-10 mb-10">
            <h1 className='mx-auto uppercase text-white font-bold text-2xl lg:text-4xl'>FORM LAPORAN LIPUTAN</h1>
            <FormRequest
                reporter={reporter}
                setReporter={setReporter}
                event={event}
                setEvent={setEvent}
                contactPerson={contactPerson}
                setContactPerson={setContactPerson}
                selected={selected}
                setSelected={setSelected}
                options={options}
                sendData={sendData}
            />
        </div>
        <Footer />
    </section>
  )
}

export default Request