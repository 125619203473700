import React, { useState } from "react";

import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import FormLayout from "../../../layouts/FormLayout";
import bgToTop from "../../../asset/pattern4-totop.svg";
import bgToBottom from "../../../asset/pattern4-tobottom.svg";

const RequirementPage = () => {
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [isOpenModal3, setIsOpenModal3] = useState(false);
  const [isOpenModal4, setIsOpenModal4] = useState(false);
  const [isOpenModal5, setIsOpenModal5] = useState(false);

  // Handle Input
  const openModal = (modalId) => {
    switch (modalId) {
      case "modal1":
        setIsOpenModal1(true);
        break;
      case "modal2":
        setIsOpenModal2(true);
        break;
      case "modal3":
        setIsOpenModal3(true);
        break;
      case "modal4":
        setIsOpenModal4(true);
        break;
      case "modal5":
        setIsOpenModal5(true);
        break;
      // ... tambahkan kasus lain untuk setiap modal
      default:
        break;
    }
  };

  const closeModal = (modalId) => {
    switch (modalId) {
      case "modal1":
        setIsOpenModal1(false);
        break;
      case "modal2":
        setIsOpenModal2(false);
        break;
      case "modal3":
        setIsOpenModal3(false);
        break;
      case "modal4":
        setIsOpenModal4(false);
        break;
      case "modal5":
        setIsOpenModal5(false);
        break;
      // ... tambahkan kasus lain untuk setiap modal
      default:
        break;
    }
  };

  // Data
  const modalList = [
    { name: "modal1", imgSrc: "/req/RP_Banner.png" },
    { name: "modal2", imgSrc: "/req/FG_Banner.png" },
    { name: "modal3", imgSrc: "/req/VG_Banner.png" },
    { name: "modal4", imgSrc: "/req/DG_Banner.png" },
    { name: "modal5", imgSrc: "/req/WM_Banner.png" },
  ];

  return (
    <FormLayout>
      <div className="mt-[72px] md:mt-[68px] bg-hero relative z-10">
        <div className="flex flex-col absolute inset-0 justify-between -z-10">
          <div
            className="h-40 md:h-80 md:bg-repeat-x top-0"
            style={{
              backgroundImage: `url(${bgToTop})`,
              backgroundSize: "contain",
            }}
          ></div>
          <div
            className="h-40 md:h-80 md:bg-repeat-x bottom-0"
            style={{
              backgroundImage: `url(${bgToBottom})`,
              backgroundSize: "contain",
            }}
          ></div>
        </div>

        <div className="container mx-auto px-10 md:px-4 py-12 max-w-screen-lg">
          <h2 className=" text-xl md:text-3xl font-bold mb-5 text-center text-white">PORTOFOLIO REQUIREMENTS</h2>

          <div className="grid-cols-none grid gap-4 md:grid-cols-3 mb-8 md:gap-6">
            {modalList.map((mdl) => (
              <div key={mdl.name} className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                <button onClick={() => openModal(mdl.name)}>
                  <img className="h-auto max-w-full rounded-lg" src={mdl.imgSrc} alt="" />
                </button>
              </div>
            ))}
          </div>

          <h2 className="text-xl md:text-3xl font-bold mb-5 text-center text-white">SUBMISSION REQUIREMENTS</h2>
          <ol className="space-y-1 list-decimal list-inside text-white mb-8">
            <li>
              <span className="font-semibold text-white">Participants create a google drive link containing their respective portfolios with the format name "Divisi_Name_NRP".</span>
            </li>
            <li>
              <span className="font-semibold text-white">Make sure the status link that is shared can be seen by others.</span>
            </li>
            <li>
              <span className="font-semibold text-white">Collect the link on the registration form on the web ENT.</span>
            </li>
            <li>
              <span className="font-semibold text-white">Also enter your creative CV in the folder</span>
            </li>
          </ol>

          <h2 className="text-xl md:text-3xl font-bold mb-5 text-center text-white">DOCUMENT REQUIREMENTS</h2>

          <ol className="space-y-4 list-decimal list-inside text-white font-semibold mb-8">
            <li>Participants are required to prepare a clear plastic L-folder.</li>
            <li>
              The documents to be submitted include :
              <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                <li>A recent formal photo (4x6 size) with a red or blue background.</li>
                <li>A resume generated from the form on the website, printed on A4 paper.</li>
                <li>A biodata form generated from the ENT registration website.</li>
                <li>A photocopy of any award certificates (if available).</li>
                <li>A photocopy of your Student ID card (KTM) without trimming (optional: temporary KTM from MIS or a screenshot of your Online MIS account).</li>
              </ul>
            </li>
            <li>Place all the documents in the clear plastic L-folder and secure them with a paperclip.</li>
            <li>Submit the documents to the ENT lab in Room PS-01.11 between 09:00 and 17:00 WIB. Please confirm with the contact person before collecting them.</li>
          </ol>

          <h2 className="text-xl md:text-3xl font-bold mb-5 text-center text-white">LINKS</h2>
          <div className="flex items-center justify-center text-center gap-3">
            <a
              href="https://drive.google.com/drive/folders/1lRFoNjpHz_16J3uXAS7ISyeGQVwrX3ac?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 inline-flex text-center items-center justify-center font-medium rounded-lg bg-teal-500 text-white hover:bg-teal-700"
            >
              TWIBBON
              <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
            <a href="/Guide Book.pdf" target="_blank" rel="noopener noreferrer" className="p-3 inline-flex text-center items-center justify-center font-medium rounded-lg bg-teal-500 text-white hover:bg-teal-700">
              GUIDE REGISTER
              <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
          </div>

          <div className="mt-8 flex justify-center gap-4">
            <Link to={"/registration"}>
              {" "}
              <Button className="bg-sky-900">Regist Now !!</Button>
            </Link>
            <Link to={"/participant"}>
              {" "}
              <Button className="bg-teal-500">Sudah daftar ?</Button>
            </Link>
          </div>
        </div>

        {isOpenModal1 && (
          <div className="fixed inset-0  flex justify-center items-center backdrop-blur-sm bg-opacity-25">
            <div className="bg-white mx-3 rounded-xl max-w-4xl">
              <img src="req/RP.png" className="rounded-lg" alt="" />
              <div className="mx-auto text-center">
                <button className=" bg-sky-900 py-2 px-4 text-xl rounded-full text-white font-semibold my-4" onClick={() => closeModal("modal1")}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {isOpenModal2 && (
          <div className="fixed inset-0  flex justify-center items-center backdrop-blur-sm bg-opacity-25">
            <div className="bg-white mx-3 rounded-xl max-w-4xl">
              <img src="req/FG.png" className="rounded-lg" alt="" />
              <div className="mx-auto text-center">
                <button className=" bg-sky-900  py-2 px-4 text-xl rounded-full text-white font-semibold  my-4 " onClick={() => closeModal("modal2")}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {isOpenModal3 && (
          <div className="fixed inset-0 flex justify-center items-center backdrop-blur-sm bg-opacity-25">
            <div className="bg-white mx-3 rounded-xl max-w-4xl">
              <img src="req/VG.png" className="rounded-lg" alt="" />
              <div className="mx-auto text-center">
                <button className=" bg-sky-900 py-2 px-4 text-xl rounded-full text-white font-semibold  my-4 " onClick={() => closeModal("modal3")}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {isOpenModal4 && (
          <div className="fixed inset-0  flex justify-center items-center backdrop-blur-sm bg-opacity-25">
            <div className="bg-white mx-3 rounded-xl max-w-4xl">
              <img src="req/DG.png" className="rounded-lg" alt="" />
              <div className="mx-auto text-center">
                <button className=" bg-sky-900  py-2 px-4 text-xl rounded-full text-white font-semibold  my-4 " onClick={() => closeModal("modal4")}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {isOpenModal5 && (
          <div className="fixed inset-0  flex justify-center items-center backdrop-blur-sm bg-opacity-25">
            <div className="bg-white mx-3 rounded-xl max-w-4xl">
              <img src="req/WM.png" className="rounded-lg" alt="" />
              <div className="mx-auto text-center">
                <button className=" bg-sky-900 rounded-full py-2 px-4 text-xl  text-white font-semibold  my-4 " onClick={() => closeModal("modal5")}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </FormLayout>
  );
};
export default RequirementPage;
