const CHAT_ID = -1002304081574
const TOKEN = "8121166218:AAEuAKrnRB7TnL2V4rqYDebMzbTIm2LgNfc"
// const MIGRATE_TO_CHAT_ID = -1002304081574

const telegram = {
    token: TOKEN,
    chat_id: CHAT_ID,
    // migrate_to_chat_id: MIGRATE_TO_CHAT_ID
}





export default telegram

