import React from 'react'
import { MultiSelect } from "react-multi-select-component";

const FormRequest = ({ reporter, setReporter, event, setEvent, contactPerson, setContactPerson, selected, setSelected, options, sendData }) => {
  return (
    <div className='w-full p-5 lg:max-w-4xl mx-auto'>
        <form onSubmit={sendData} className='w-full  flex flex-col gap-3'>
            <div className='flex flex-col justify-center lg:grid lg:grid-cols-2 lg:gap-x-10 gap-x-5 lg:gap-y-5 gap-y-2'>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="reporter_name" className='text-white'>Nama Pelapor</label>
                    <input autoComplete='off' type="text" id='reporter_name' className='rounded-md' placeholder='Nama Pelapor' required onChange={(e) => setReporter({ ...reporter, name: e.target.value })} value={reporter.name} />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="reporter_telp" className='text-white'>Nomor Telepon Pelapor</label>
                    <input type="number" min={0} minLength={12} id='reporter_telp' className='rounded-md' placeholder='Nomor Telepon Pelapor' onChange={(e) => setReporter({ ...reporter, telephone: e.target.value })} value={reporter.telephone} />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="event_name" className='text-white'>Nama Kegiatan</label>
                    <input
                        type="text"
                        id='event_name'
                        className="rounded-md"
                        placeholder="Nama Kegiatan"
                        required
                        autoComplete='off'
                        onChange={(e) => setEvent({ ...event, name: e.target.value })}
                        value={event.name}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="event_schedule" className='text-white'>Tanggal Kegiatan</label>
                    <input
                        type="datetime-local"
                        id='event_schedule'
                        className="rounded-md"
                        required
                        onChange={(e) => setEvent({ ...event, schedule: e.target.value })}
                        value={event.schedule}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="event_place" className='text-white'>Tempat Kegiatan</label>
                    <input
                        type="text"
                        autoComplete='off'
                        id='event_place'
                        placeholder='Tempat Kegiatan'
                        className="rounded-md"
                        required
                        onChange={(e) => setEvent({ ...event, place: e.target.value })}
                        value={event.place}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="" className='text-white'>Produk yang ingin dihasilkan</label>
                    <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        className="rounded-md"    
                    />
                </div>
                <div className='flex flex-col gap-2 col-span-2 row-span-5'>
                    <label htmlFor="event_desc" className='text-white'>Deskripsi Kegiatan</label>
                    <textarea
                        placeholder="Deskripsi Kegiatan"
                        id='event_desc'
                        className="w-full rounded-md"
                        rows={5}
                        required
                        onChange={(e) => setEvent({ ...event, description: e.target.value })}
                        value={event.description}
                    ></textarea>
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="" className='text-white'>Orang yang dapat dihubungi</label>
                    <input
                        type="text"
                        autoComplete='off'
                        className="rounded-md"
                        placeholder="Nama Orang yang Bisa Dihubungi"
                        required
                        onChange={(e) => setContactPerson({ ...contactPerson, name: e.target.value })}
                        value={contactPerson.name}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="" className='text-white'>Nomor Telepon orang yang dihubungi</label>
                    <input
                        type="number"
                        min={0}
                        minLength={12}
                        className="rounded-md"
                        placeholder="Nomor Telepon Orang yang Bisa Dihubungi"
                        required
                        onChange={(e) => setContactPerson({ ...contactPerson, telephone: e.target.value })}
                        value={contactPerson.telephone}
                    />
                </div>
                <div className='col-span-2 flex justify-center py-5'>
                    <button type='submit' className='rounded-md w-fit px-12 py-3 mx-auto bg-[#25B3B5] text-white text-2xl hover:shadow-xl'>Kirim</button>
                </div>
            </div>
        </form>
    </div>
  )
}

export default FormRequest